import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import { styled, alpha } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import { createTheme, ThemeProvider, useTheme } from '@mui/material/styles';
import Image from 'mui-image';
// import Logo from '../../../images/parat-logo.svg';
import { useLocation, useNavigate } from "react-router-dom";
import { AuthService } from '../../../services/AuthService';
import { DarkModeSwitch } from '../../ui/DarkModeSwitch';
import { authStore, tenantStore } from '../../../App'
import FallbackImage from '../../file/FallbackImage';
import { Paper, Popper } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { create } from 'zustand';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Menuitem } from '../../../model/System';

function firstPath(path) {
  return path
    .split('/')
    .filter(Boolean)
    .map(segment => decodeURIComponent(segment))[0];
}

function Header2() {
  const navigate = useNavigate();
  const location = useLocation();
  const firstRoute = firstPath(location.pathname);
  const theme = useTheme();
  const { mainRoutes, profileRoutes, userName } = authStore();
  const { displayName } = tenantStore();
  const { logout } = AuthService();
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);
  const [anchorEl, setAnchorEl] = React.useState(null); // Tracks which main path is hovered
  const [openMenu, setOpenMenu] = React.useState(false); // Tracks if the menu is open
  const [menuRef, setMenuRef] = React.useState(null); // Ref for the currently active menu
  const [activeSubPaths, setActiveSubPaths] = React.useState<Menuitem[]>([]); // Ref for the currently active sub paths
  const [openPopper, setOpenPopper] = React.useState(false);
  const [disableTouchRouting, setDisableTouchRouting] = React.useState(false);
  const popperRef = React.useRef(null); // Ref for the popper element
  const mainPathRefs = React.useRef([]); // Array of refs for each main path

  const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    marginRight: '5px',
    // width: 'auto',
    // [theme.breakpoints.up('sm')]: {
    ml: theme.spacing(1),
    width: 'auto',
    // },
  }));

  const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }));

  const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)})`,
      transition: theme.transitions.create('width'),
      // width: '100%',
      // [theme.breakpoints.up('sm')]: {
      width: '0ch',
      '&:focus': {
        width: '20ch',
      },
      // },
    },
  }));

  // hover with mouse over main navigation buttons
  const handleMouseEnterMain = (event, index, page) => {
    // don't close main menu on mobile
    if (!openPopper) {
      // console.log("closing from mouse enter")
      closeMenu();
    } else if (openMenu) {
      // console.log("closing sub menues")
      setOpenMenu(false);
      return;
    }

    // let mainPath: string = mainPathRefs.current[index].innerHTML.split("<")[0];
    // console.log("entered", mainPath, "pagename", page.name, "openMenu", openMenu);

    if (page.children?.length > 0) {
      setActiveSubPaths(page.children);
      setAnchorEl(event.currentTarget);
      setOpenMenu(true);
      setMenuRef(mainPathRefs.current[index]);
    }
  };

  // Handle navigation on main or sub path click
  const handleNavigationClick = (path, subPath = false) => {
    if (disableTouchRouting && !subPath) {
      return;
    }
    navigate(path);
    closeMenu();
  };

  // Handle navigation on main or sub path click for hamburger menu
  const handlePopperNavigationClick = (path) => {
    navigate(path);
    setAnchorElNav(null);
    setOpenPopper(false);
    setOpenMenu(false);
  };

  // handle main buttons touch on desktop view
  const handleTouchStartMain = (event, index, page) => {
    let subRoutes = page.children || [];
    if (subRoutes.length > 0) {
      setDisableTouchRouting(true);
      if (openMenu && anchorEl === mainPathRefs.current[index]) {
        setDisableTouchRouting(false);
        handleNavigationClick(page.url);
      } else {
        setActiveSubPaths(page.children);
        setAnchorEl(event.currentTarget);
        setOpenMenu(true);
        setMenuRef(mainPathRefs.current[index]);
      }
    } else {
      setDisableTouchRouting(false);
      handleNavigationClick(page.url);
    }
  };

  const closeMenu = () => {
    setOpenMenu(false);
    setAnchorEl(null);
    setMenuRef(null);
    setAnchorElNav(null);
    setOpenPopper(false);
  };

  // Store refs dynamically for multiple main paths
  const addMainPathRef = (element, index) => {
    mainPathRefs.current[index] = element;
  };

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
    setOpenPopper(!openPopper);
  };

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const closePopper = () => {
    setOpenPopper(false);
    setAnchorEl(null);
  };

  const handleSettingNavigation = (page: string) => {
    if (page === 'Logout') {
      logout();
      navigate('/');
    } else {
      handleCloseUserMenu();
      navigate('/' + page);
    }
  }

  return (
    <AppBar position="static" sx={{ backgroundColor: theme.palette.primary.main, backgroundImage: 'none' }}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          {/* style={{ marginRight: "5px", display: Boolean(anchorElUser) ? 'block' : 'none' }} */}
          <Box sx={{ display: { xs: 'none', md: 'flex' }, alignItems: 'center', height: "34px", cursor: 'pointer' }} onClick={() => navigate('/home')}>
            <Box sx={{ mr: 1, width: "26px", height: "26px" }}>
              <FallbackImage
                primarySrc="/api/static/logo"
                secondarySrc="/default/logo.png"
                altText="logo"
              />
              {/* <Image src={Logo} width="26px" height="34px" duration={0} shiftDuration={0} /> */}
            </Box>
            <Typography
              variant="h6"
              noWrap
              // component="a"
              sx={{
                mr: 2,
                display: { xs: 'none', md: 'flex' },
                fontFamily: 'monospace',
                fontWeight: 700,
                letterSpacing: displayName.length > 6 ? '.1rem' : '.3rem',
                color: 'inherit',
                textDecoration: 'none',
              }}
            >
              {displayName.toUpperCase()}
            </Typography>
          </Box>

          {/* Mobile navbar */}
          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Popper
              id="menu-appbar"
              anchorEl={anchorElNav}
              open={openPopper}
              ref={anchorElNav}
            >
              <Paper elevation={6} sx={{ backgroundColor: theme.palette.mode === 'dark' ? '#10151a' : '#eaeaea', }}>
                {mainRoutes.map((page, index) => {
                  return (
                    <Box key={index} sx={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                      <MenuItem key={index} sx={{ width: "100%" }} onClick={() => handlePopperNavigationClick(page.url)}>
                        <Typography textAlign="center">{page.name}</Typography>
                      </MenuItem>
                      {page.children && page.children.length > 0 &&
                        <IconButton onClick={(event) => handleMouseEnterMain(event, index, page)}>
                          <ChevronRightIcon sx={{ color: theme.palette.mode === "dark" ? "white" : "black" }} />
                        </IconButton>
                      }
                    </Box>
                  )
                })}
              </Paper>
            </Popper>
          </Box>
          <Box sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }}>
            <Box sx={{ width: "26px", height: "34px" }}>
              <FallbackImage
                primarySrc="/api/static/logo"
                secondarySrc="/default/logo.png"
                altText="logo"
              />
              {/* <Image src={Logo} width="26px" height="34px" duration={0} shiftDuration={0} /> */}
            </Box>
          </Box>
          <Typography
            variant="h5"
            noWrap
            component="a"
            href="#app-bar-with-responsive-menu"
            sx={{
              mr: 2,
              display: { xs: 'flex', md: 'none' },
              flexGrow: 1,
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            {displayName.toUpperCase()}
          </Typography>

          {/* Desktop navbar */}
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            {mainRoutes.map((page, index) => {
              const isActive = firstRoute === firstPath(page.url);
              return (
                <Button
                  key={index}
                  aria-haspopup="true"
                  onMouseOver={(event) => handleMouseEnterMain(event, index, page)}
                  onTouchStart={(event) => handleTouchStartMain(event, index, page)}
                  onClick={() => handleNavigationClick(page.url)}
                  ref={(element) => addMainPathRef(element, index)} // Dynamic ref assignment
                  sx={{
                    my: 2, display: 'flex', cursor: 'pointer',
                    "& .MuiButton-endIcon": { m: 0 },
                    color: "white",
                    transition: "all 0.3s ease",
                    backgroundColor: isActive ? theme.palette.primary.light : "inherit",
                    boxShadow: isActive ? "rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px" : "none",
                    "&:hover": {
                      backgroundColor: "rgba(255, 255, 255, 0.1)",
                    },
                  }}
                  endIcon={page.children && page.children.length > 0 && <ArrowDropDownIcon />}
                >
                  {page.name}
                </Button>
              )
            })}
          </Box>
          <Popper
            open={openMenu}
            anchorEl={anchorEl}
            onMouseLeave={() => {
              // console.log("leave popper"); 
              closeMenu()
            }}
            placement="bottom-start"
            ref={(element) => setMenuRef(element)}
            sx={{ minWidth: 200 }}
          >
            <Paper>
              {activeSubPaths.map((page, index) => {
                return (
                  <MenuItem
                    key={index}
                    onClick={() => handleNavigationClick(page.url, true)}
                    sx={{
                      display: 'block',
                      cursor: 'pointer',
                      "&:hover": {
                        backgroundColor: theme.palette.mode === "dark" ? "rgba(255, 255, 255, 0.1)" : "inherit",
                      },
                    }}
                  >
                    {page.name}
                  </MenuItem>
                )
              })}
            </Paper>
          </Popper>

          <Box>
            <Search>
              <SearchIconWrapper>
                <SearchIcon />
              </SearchIconWrapper>
              <StyledInputBase
                placeholder="Search…"
                inputProps={{ 'aria-label': 'search' }}
              />
            </Search>
          </Box>
          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                {/* @todo: add profile pic eks: src="/static/public/avatar/2.png" */}
                <Avatar alt={userName} />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: '45px', minHeight: '200px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              <MenuItem key='darkMode'>
                <DarkModeSwitch />
              </MenuItem>
              {profileRoutes.map((page, index) => (
                <MenuItem key={index} onClick={() => handleSettingNavigation(page.url)}>
                  <Typography textAlign="center">{page.name}</Typography>
                </MenuItem>
              ))}
              <MenuItem key={'Logout'} onClick={() => handleSettingNavigation('Logout')}>
                <Typography textAlign="center">Logout</Typography>
              </MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default Header2;