import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import FullLayout from '../../../../components/layouts/FullLayout';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Grid } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import { Alert, CircularProgress } from '@mui/material';
import { FilterGroup } from './FilterGroup';
import { TableHolder } from './TableHolder';
import { GroupLevel, FilterData, SelectedFilter, SelectedGroup } from '../Interface';
import { useApi } from '../../../../services/HttpService';
import { DarkModeSwitch } from '../../../../components/ui/DarkModeSwitch';


const steps = ['Velg yrkesgruppe', 'Velg data', 'Se data'];
var groups: GroupLevel[] = [];

export const SaleryStatistics = () => {
  const { uid } = useParams(); // send with request to authenticate user
  const { api: filterApi, response: filterResponse, isLoading: filterIsLoading, error: filterError } = useApi(false);
  const [activeStep, setActiveStep] = React.useState(0); // stepper index
  const [availableFilters, setAvailableFilters] = React.useState<SelectedGroup[]>([]); // set available filters in next level
  const [selectedFilters, setSelectedFilters] = React.useState<SelectedFilter[]>([]); // list of selected filter at each level
  const [canEdit, setCanEdit] = React.useState(false);


  React.useEffect(() => {
    ///////// TEST REMOVE /////////
    // setActiveStep(2);
    ///////// TEST REMOVE /////////

    const getSelectableFilters = async () => {
      await filterApi("/getAdminSaleryStatisticFilters", "POST", { uid: uid })
    }

    getSelectableFilters();
  }, []);

  React.useEffect(() => {
    if (filterResponse) {
      let newGroups: GroupLevel[] = [];
      let newAvailbaleFilters: SelectedGroup[] = [];
      // let emptyGroup: GroupLevel = { id: "", header: "", tooltip: "", data: [] }

      const levels = ['gl2', 'gl3', 'gl4', 'gl5', 'gl6'];
      for (const level of levels) {
        const g = filterResponse[level];
        if (g) {
          const group: GroupLevel = { id: g.id, header: g.header, tooltip: g.tooltip, data: level === 'gl2' ? g.data : [] };
          newAvailbaleFilters.push({ selected_index: -1, group });
          const nGroup = { ...group, data: g.data };
          newGroups.push(nGroup);
        }
      }

      groups = newGroups;
      setAvailableFilters(newAvailbaleFilters);
      setCanEdit(filterResponse.is_admin)
    }
  }, [filterResponse])

  const updateDisaplyName = (id: number, tableName: string, displayName: string) => {
    let group = groups.find(item => item.id === tableName);
    if (group) {
      let filter = group.data.find(item => item.id === id);
      if (filter) {
        filter.display_name = displayName;
      }
    }
  }

  const UpdateSelectedFilters = (selected_index: number, id: string, filter: FilterData) => {
    let levelIndex = availableFilters.findIndex(item => item.group.id === id)
    let groupData: FilterData[] = [];
    if (groups.length > levelIndex + 1) {
      let groupLevel = groups[levelIndex + 1];
      groupData = groupLevel.data.filter(item => item.parent_id === filter.id);

      // loop over a all group levels, set selected on clicked, available filters on next level and clear all remaining selectable filters.
      let newAvailbaleFilters: SelectedGroup[] = [];
      for (let i = 0; i < availableFilters.length; i++) {
        let g = availableFilters[i];

        if (i < levelIndex + 1) {
          if (i === levelIndex) {
            g.selected_index = selected_index;
          }
        }
        else if (i === levelIndex + 1) {
          g.group.data = groupData;
          g.selected_index = -1;
        }
        else {
          g.group.data = [];
          g.selected_index = -1;
        }

        newAvailbaleFilters.push(g);
      }
      setAvailableFilters(newAvailbaleFilters);

    } else if (groups.length === levelIndex + 1) {
      // if last level of filters are clicked, only set the selected filter
      let filters = [...availableFilters]
      filters[levelIndex].selected_index = selected_index;
      setAvailableFilters(filters);
    }


    let newFilter: SelectedFilter = { id: id, filter: filter };
    let index = selectedFilters.findIndex(item => item.id === id);
    if (index >= 0) {
      let newFilters = selectedFilters.slice(0, index);
      setSelectedFilters(() => [...newFilters, newFilter]);
    } else {
      setSelectedFilters(selectedFilters => [...selectedFilters, newFilter]);
    }

    // go to next step
    if (id === "gl4" || id === "gl6") {
      handleNext();
    }

    // console.log("index", index, "levelIndex", levelIndex, "selected:", selectedFilters)
  }

  const handleSetStep = (step: number) => {
    setActiveStep(step);
  }

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  return (
    <Box sx={{ width: '100%', maxWidth: 1200, minWidth: 880, margin: '0 auto' }}>
      {/* RMOVE */}
      {/* <DarkModeSwitch /> */}

      <Stepper activeStep={activeStep}>
        {steps.map((label, index) => {
          const stepProps: { completed?: boolean } = {};
          const labelProps: {
            // optional?: React.ReactNode;
          } = {};
          return (
            <Step key={label} {...stepProps} onClick={() => handleSetStep(index)}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>

      {activeStep === steps.length ? (
        <React.Fragment>
          <Typography>
            All steps completed - you&apos;re finished
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
            <Box sx={{ flex: '1 1 auto' }} />
            <Button onClick={handleReset}>Reset</Button>
          </Box>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <Box sx={{ m: 2, flexGrow: 1, minHeight: 400 }}>
            {filterIsLoading ? (
              <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                <CircularProgress color="info" />
              </Box>
            ) : filterError ? (
              <Alert severity='error'>{filterError}</Alert>
            ) : filterResponse ? (
              <>
                {activeStep === 0 && (
                  <Grid container spacing={2}>
                    <Grid item xs={4}>
                      {availableFilters.length > 0 && (
                        <FilterGroup
                          canEdit={canEdit}
                          groupLevel={availableFilters[0]}
                          updateSelectedFilters={UpdateSelectedFilters}
                          updateDisaplyName={updateDisaplyName}
                        />
                      )}
                    </Grid>
                    <Grid item xs={4}>
                      {availableFilters.length > 1 && (
                        <FilterGroup
                          canEdit={canEdit}
                          groupLevel={availableFilters[1]}
                          updateSelectedFilters={UpdateSelectedFilters}
                          updateDisaplyName={updateDisaplyName}
                        />
                      )}
                    </Grid>
                    <Grid item xs={4}>
                      {availableFilters.length > 2 && (
                        <FilterGroup
                          canEdit={canEdit}
                          groupLevel={availableFilters[2]}
                          updateSelectedFilters={UpdateSelectedFilters}
                          updateDisaplyName={updateDisaplyName}
                        />
                      )}
                    </Grid>
                  </Grid>
                )}
                {activeStep === 1 && (
                  <Grid container spacing={2} sx={{ justifyContent: 'center' }}>
                    <Grid item xs={4}>
                      {availableFilters.length > 3 && (
                        <FilterGroup
                          canEdit={canEdit}
                          groupLevel={availableFilters[3]}
                          updateSelectedFilters={UpdateSelectedFilters}
                          updateDisaplyName={updateDisaplyName}
                        />
                      )}
                    </Grid>
                    <Grid item xs={4}>
                      {availableFilters.length > 4 && (
                        <FilterGroup
                          canEdit={canEdit}
                          groupLevel={availableFilters[4]}
                          updateSelectedFilters={UpdateSelectedFilters}
                          updateDisaplyName={updateDisaplyName}
                        />
                      )}
                    </Grid>
                  </Grid>
                )}
                {activeStep === 2 && (
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TableHolder
                        selectedFilters={selectedFilters}
                      />
                    </Grid>
                  </Grid>
                )}
              </>
            ) : null}
            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
              <Button
                color="darkgrey"
                variant="contained"
                disabled={activeStep === 0}
                onClick={handleBack}
                size="large"
                sx={{ ml: 2 }}
              >
                Tilbake
              </Button>
              <Box sx={{ flex: '1 1 auto' }} />
              <Button
                color="success"
                variant="contained"
                disabled={activeStep === steps.length - 1}
                onClick={handleNext}
                size="large"
                sx={{ mr: 2 }}
              >
                Neste
              </Button>
            </Box>
          </Box>
        </React.Fragment>
      )}
    </Box>
  );
}