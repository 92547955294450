import { Box, CircularProgress } from '@mui/material';
import * as React from 'react';

interface CenterSpinnerProps {
  loading: boolean;
  cover?: boolean;
}

export const CenterSpinner: React.FC<CenterSpinnerProps> = ({ loading, cover }) => {

  if (!loading) {
    return null
  }
  return (
    <Box sx={{ width: cover ? '100%' : 'initial', display: 'flex', justifyContent: 'center' }}>
      <CircularProgress color="info" />
    </Box>
  )
}