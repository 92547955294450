export interface Menuitem {
  id: number;
  name: string;
  parent_id: number;
  priority: number;
  license_paid: boolean;
  validated_at: Date;
  description: string;
  url: string;
  level: number;
  sys_form_id: number;
  open_new_tab: boolean;

  status: number;
  version: number;

  // extras
  isMenuitem: boolean;
  selectedStatus: string;
  children: Menuitem[];
}

export const newMenuitem = (): Menuitem => ({
  id: 0,
  name: "",
  parent_id: 0,
  priority: 0,
  license_paid: true,
  validated_at: new Date,
  description: "",
  url: "",
  level: 0,
  sys_form_id: 0,
  open_new_tab: false,

  status: 0,
  version: 0,

  isMenuitem: false,
  selectedStatus: "",
  children: [],
});

export interface Accessgroup {
  id: number;
  name: string;
  parent_id: number;
  employee: boolean;
  member: boolean;
  tillitsvalgt: boolean;
  company: boolean;
  isAccessgroup: boolean;
  selectedStatus: string;

  status: number;
  version: number;

  children: Accessgroup[];
}

export const newAccessgroup = (): Accessgroup => ({
  id: 0,
  name: "",
  parent_id: 0,
  employee: false,
  member: false,
  tillitsvalgt: false,
  company: false,
  isAccessgroup: true,
  selectedStatus: "",

  status: 0,
  version: 0,

  children: [],
});

export interface AccessgroupMenuitem {
  id: number;
  name: string;
  accessgroup_id: number;
  sys_menuitem_id: number;
  status: number;
}

export interface MenuSettings {
  fields: any;
  forms: any;
  menuitems: Menuitem[];
  accessgroups: Accessgroup[];
  accessgroupMenuitems: AccessgroupMenuitem[];
}

export function createMenuitemHirarchy(menuitems: Menuitem[]): Menuitem[] {
  const menuItemMap = new Map<number, Menuitem>();

  // Initialize the map and set the children array for each item
  menuitems.forEach(item => {
    let children: Menuitem[] = [];
    item.children = children;
    item.isMenuitem = true;
    menuItemMap.set(item.id, item);
  });

  const hierarchy: Menuitem[] = [];

  menuitems.forEach(item => {
    const parent = menuItemMap.get(item.parent_id);
    if (item.parent_id === 0 || !parent) {
      // no parent
      hierarchy.push(item);
    } else {
      // add the item to its children
      if (parent) {
        parent.children.push(item);
      }
    }
  });

  // Sort each level by priority
  function sortHierarchy(items: Menuitem[]) {
    items.sort((a, b) => a.priority - b.priority);
    items.forEach(item => sortHierarchy(item.children));
  }

  sortHierarchy(hierarchy);
  return hierarchy;
}

export function createHierarchy<T extends {
  id: number;
  parent_id: number;
  children?: T[];
  priority?: number
}>(items: T[]): T[] {
  const itemMap = new Map<number, T>();

  // Initialize the map and set the children array for each item
  items.forEach((item) => {
    item.children = []; // Ensure `children` exists
    itemMap.set(item.id, item);
  });

  const hierarchy: T[] = [];

  items.forEach((item) => {
    const parent = itemMap.get(item.parent_id);
    if (item.parent_id === 0 || !parent) {
      // No parent
      hierarchy.push(item);
    } else {
      // add the item to its children
      if (parent) {
        parent.children?.push(item);
      }
    }
  });

  // Sort each level by priority, if it exists
  function sortHierarchy(items: T[]) {
    if (items.length === 0) return;

    if ("priority" in items[0]) {
      items.sort((a, b) => (a.priority ?? 0) - (b.priority ?? 0));
    }

    items.forEach((item) => sortHierarchy(item.children || []));
  }

  // Only sort if priority exists on the first item
  if ("priority" in hierarchy[0]) {
    sortHierarchy(hierarchy);
  }

  return hierarchy;
}

// Flatten the hierarchy into a linear array while maintaining parent-child order
export function flattenHierarchy<T extends {
  children?: T[];
}>(items: T[]): T[] {
  const flattened: T[] = [];

  function traverse(items: T[]) {
    items.forEach((item) => {
      flattened.push(item);
      if (item.children) {
        traverse(item.children);
      }
    });
  }

  traverse(items);
  return flattened;
}

export function countHierarchyChildren<T extends { children: T[] }>(item: T): number {
  let count = item.children.length; // Count immediate children
  item.children.forEach((child) => {
    count += countHierarchyChildren(child); // Recursively count their children
  });
  return count;
}