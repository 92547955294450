import { Alert, Box, Button, Checkbox, CircularProgress, FormControlLabel, FormGroup, IconButton, MenuItem, TextField, Typography, Select, InputLabel, FormControl } from "@mui/material";
import React, { useState } from "react";
import { DarkModeSwitch } from "../../../../components/ui/DarkModeSwitch";
import { TableHolder } from "./TableHolder";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useApi } from "../../../../services/HttpService";
import { FilterData, SelectedGroup } from "../Interface";
import { useParams } from "react-router-dom";


export const MemberStatistic = () => {
  const { uid } = useParams(); // send with request to authenticate user
  const { api: filterApi, response: filterResponse, isLoading: filterIsLoading, error: filterError } = useApi(false);
  const [monthlySalary, setMonthlySalary] = React.useState<string>('');
  const [bonus, setBonus] = React.useState<string>('');
  const [additional, setAdditional] = React.useState<string>('');
  const [overtime, setOvertime] = React.useState<string>('');
  const [warning, setWarning] = React.useState<string>('');
  const [isAdmin, setIsAdmin] = React.useState(false);
  const [actAsMemberNumber, setActAsMemberNumber] = React.useState(''); // allows admins to act as a member
  const [displayTableHolder, setDisplayTableHolder] = React.useState(false); // Display the table with the calculated values
  const [availableFilters, setAvailableFilters] = React.useState<SelectedGroup[]>([]); // Available filters for the dropdown menus
  const [selectedGl2, setSelectedGl2] = React.useState<number | string | undefined>(undefined); // Track selected gl2
  const [selectedGl3, setSelectedGl3] = React.useState<number | string | undefined>(undefined); // Track selected gl3
  const [selectedGl4, setSelectedGl4] = React.useState(""); // Track selected gl4
  const [selectedGl5, setSelectedGl5] = React.useState(""); // Track selected gl5
  const [selectedGl6, setSelectedGl6] = React.useState(""); // Track selected gl6
  const [gl5Options, setGl5Options] = React.useState<FilterData[]>([]); // gl5 options
  const [gl6Options, setGl6Options] = React.useState<FilterData[]>([]); // gl6 options

  // Information text for the input fields
  const [infoText, setInfoText] = useState({
    actAsMember: { visible: false, text: "Som administrator kan du sette et medlemsnummer for å se statestikken til det medlemmet. La være blank for din egen." },
    monthlySalary: { visible: false, text: "Hva du har i månedslønn før skatt. Din årslønn før skatt delt på 12." },
    bonus: { visible: false, text: "Månedlig bonus før skatt." },
    additional: { visible: false, text: "Uregelmessige tillegg som ikke inngår i fast lønn." },
    overtime: { visible: false, text: "Månedlig betaling for overtid." },
    group: { visible: false, text: "Yrkesgruppe." },
    educationLevel: { visible: false, text: "Ditt utdanningsnivå." },
    yearsSinceEducation: { visible: false, text: "Antall år siden du fullførte utdanning." }
  });

  // Fetching filter data
  const getSelectableFilters = async () => {
    await filterApi("/getMemberSaleryStatisticFilters", "POST", { "uid": uid, "act_as_member_number": actAsMemberNumber })
  }

  React.useEffect(() => {
    getSelectableFilters();
  }, []);

  // Fetch or filter gl4 options for the "Grupper" dropdown
  React.useEffect(() => {
    console.log("filterResponse", filterResponse)
    if (filterResponse) {
      // set selected gl2 (not important)
      if (filterResponse["gl2"]) {
        const filteredData = filterResponse["gl2"].data;
        if (filteredData && filteredData.length > 0) {
          setSelectedGl2(filteredData[0].id);
        }
      }

      // set selected gl3
      let gl3id = 0;
      if (filterResponse["gl3"]) {
        const filteredData = filterResponse["gl3"].data;
        if (!filteredData || filteredData.length === 0) {
          setWarning("Du er ikke tilknyttet en tariffavtale");
        } else {
          if (filteredData.length > 1) {
            setWarning("Du er tilknyttet flere tariffavtaler, bruker: " + filteredData[0].display_name);
          }
          gl3id = filteredData[0].id;
          setSelectedGl3(gl3id);
        }
      }

      // Filter the data based on the selected and gl3
      let newAvailableFilters: SelectedGroup[] = [];
      if (filterResponse["gl4"]) {
        const filteredData = filterResponse["gl4"].data.filter((item: FilterData) => item.parent_id === gl3id);
        if (filteredData.length > 0) {
          const group = {
            id: filterResponse["gl4"].id,
            header: filterResponse["gl4"].header,
            tooltip: filterResponse["gl4"].tooltip,
            data: filteredData,
          };
          newAvailableFilters.push({ selected_index: -1, group });
        }
        console.log("newAvailableFilters", newAvailableFilters)
      }
      setAvailableFilters(newAvailableFilters);
      setIsAdmin(filterResponse.is_admin)
      console.log("availableFilters", availableFilters)
    }
  }, [filterResponse]);

  // "Utdanningsnivå" dropdown.
  React.useEffect(() => {
    if (selectedGl4 && filterResponse && filterResponse["gl5"]) {
      const filteredGl5Data = filterResponse["gl5"].data.filter((item: FilterData) => item.parent_id === parseInt(selectedGl4));
      setGl5Options(filteredGl5Data);
      setSelectedGl5("");
      setSelectedGl6("");
    }
  }, [selectedGl4]);

  // "År siden fullført utdanning" dropdown.
  React.useEffect(() => {
    if (selectedGl5 && filterResponse && filterResponse["gl6"]) {
      const filteredGl6Data = filterResponse["gl6"].data.filter((item: FilterData) => item.parent_id === parseInt(selectedGl5));
      setGl6Options(filteredGl6Data);
      setSelectedGl6("");
    }
  }, [selectedGl5, filterResponse]);

  // Function for handling the information icon click. Toggles the visibility of the information text.
  const handleInfoClick = (field: string | number) => {
    setInfoText({
      ...infoText,
      [field]: {
        ...infoText[field as keyof typeof infoText],
        visible: !infoText[field as keyof typeof infoText].visible
      }
    });
  };

  const actAsMemberClick = () => {
    setWarning("");
    setSelectedGl4("");
    setSelectedGl5("");
    setSelectedGl6("");
    getSelectableFilters();
  }

  // 'Kalkuler'-button function. Displays the table with the calculated values.
  const calculateButtonClick = () => {
    if (selectedGl6 === "") {
      let missings: string[] = [];
      if (selectedGl4 === "") missings.push("Grupper");
      if (selectedGl5 === "") missings.push("Utdanningsnivå");
      setWarning(missings.join(", ") + (missings.length > 0 ? " og " : "") + "År siden fullført utdanning må velges for å gå videre");
      return
    }
    setWarning("");
    setDisplayTableHolder(true);
  }

  // 'Nulstille valg'-button function. Resets all input fields.
  const resetButtonClick = () => {
    setMonthlySalary("");
    setBonus("");
    setAdditional("");
    setOvertime("");
    setSelectedGl4("");
    setSelectedGl5("");
    setSelectedGl6("");
  }

  return (
    <Box
      sx={{
        minHeight: "750px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
      }}>

      <Box
        sx={{
          width: { sm: "90vw", md: "700px" },
          minHeight: "750px",
          paddingLeft: { xs: 2, sm: 5 },
          paddingRight: { xs: 2, sm: 5 },
          paddingTop: 3,
          paddingBottom: 3,
          borderRadius: "5px",
          boxShadow: 3,
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          position: "relative",
        }}>

        {/* <Box sx={{ position: "absolute", top: 0, right: 0, m: 1 }}>
          <DarkModeSwitch />
        </Box> */}

        {/* Display the table with the user input values and salary data if the 'Kalkuler'-button has been clicked. */}
        {displayTableHolder ? (
          <>
            <Box sx={{ display: "flex", cursor: "pointer", position: "absolute", top: 0, left: 0, m: 1, p: 2 }}
              onClick={() => setDisplayTableHolder(false)}
            >
              <ArrowBackIosIcon />
              <Typography>
                Tilbake
              </Typography>
            </Box>
            <TableHolder
              actAsMemberNumber={actAsMemberNumber}
              monthlySalary={monthlySalary}
              bonus={bonus}
              additional={additional}
              overtime={overtime}
              gl2={selectedGl2 ? Number(selectedGl2) : 0}
              gl3={selectedGl3 ? Number(selectedGl3) : 0}
              group={selectedGl4 ? Number(selectedGl4) : 0}
              educationLevel={selectedGl5 ? Number(selectedGl5) : 0}
              yearsSinceEducation={selectedGl6 ? Number(selectedGl6) : 0}
            />
          </>
        ) : (
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
              <h1>Lønnsoversikt</h1>
            </Box>

            {warning && (
              <Alert sx={{ mt: 1, mb: 1 }} severity='warning'>{warning}</Alert>
            )}

            {isAdmin && (
              <Box sx={{ mb: 3, mt: 3, pt: 2, pb: 2, pl: 2, width: "100%", boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px" }}>
                <Box sx={{ display: "flex", alignItems: "center", mb: 1, width: "100%" }}>
                  <TextField
                    id="act-as-member"
                    label="Medlemsnummer"
                    aria-label="Tast inn avtalt brutto månedslønn"
                    variant="standard"
                    fullWidth
                    sx={{ mr: 1 }}
                    value={actAsMemberNumber}
                    onChange={(event) => setActAsMemberNumber(event.target.value)}
                  />
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{ fontSize: { xs: "0.7rem", sm: "1rem" } }}
                    onClick={actAsMemberClick}
                    aria-label="Nullstill alle felter"
                  >
                    Velg
                  </Button>
                  <IconButton
                    aria-label="Informasjon"
                    onClick={() => (handleInfoClick("actAsMember"))}
                  >
                    <InfoOutlinedIcon sx={{ color: "#0093ff" }} />
                  </IconButton>
                </Box>
                {infoText.actAsMember.visible && (
                  <Typography style={{ paddingRight: "8px" }} variant="body2" color="textSecondary">
                    {infoText.actAsMember.text}
                  </Typography>
                )}
              </Box>
            )}

            {/* Input fields */}
            {filterIsLoading ? (
              <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
                <CircularProgress color="info" />
              </Box>
            ) : filterError ? (
              <Alert severity='error'>{filterError}</Alert>
            ) : filterResponse ? (
              <>
                <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "100%" }}>
                  <Box sx={{ display: "flex", alignItems: "center", mb: 1, width: "100%" }}>
                    <TextField
                      id="monthly-salary"
                      label="Avtalt brutto månedslønn"
                      aria-label="Tast inn avtalt brutto månedslønn"
                      variant="standard"
                      fullWidth
                      sx={{ mr: 1 }}
                      value={monthlySalary}
                      onChange={(event) => setMonthlySalary(event.target.value)}
                    />
                    <IconButton
                      aria-label="Informasjon"
                      onClick={() => (handleInfoClick("monthlySalary"))}
                      sx={{ flexShrink: 0 }} // Prevent the button from shrinking if space is tight
                    >
                      <InfoOutlinedIcon sx={{ color: "#0093ff" }} />
                    </IconButton>
                  </Box>
                  {infoText.monthlySalary.visible && (
                    <Typography variant="body2" color="textSecondary">
                      {infoText.monthlySalary.text}
                    </Typography>
                  )}

                  <Box sx={{ display: "flex", alignItems: "center", mb: 1, width: "100%" }}>
                    <TextField
                      id="bonus"
                      label="Bonus"
                      aria-label="Tast inn bonus"
                      variant="standard"
                      fullWidth
                      sx={{ mb: 1 }}
                      value={bonus}
                      onChange={(event) => setBonus(event.target.value)}
                    />
                    <IconButton
                      aria-label="Informasjon"
                      onClick={() => (handleInfoClick("bonus"))}
                      sx={{ flexShrink: 0 }}
                    >
                      <InfoOutlinedIcon sx={{ color: "#0093ff" }} />
                    </IconButton>
                  </Box>
                  {infoText.bonus.visible && (
                    <Typography variant="body2" color="textSecondary">
                      {infoText.bonus.text}
                    </Typography>
                  )}

                  <Box sx={{ display: "flex", alignItems: "center", mb: 1, width: "100%" }}>
                    <TextField
                      id="additional"
                      label="Uregelmessige tillegg"
                      aria-label="Tast inn uregelmessige tillegg"
                      variant="standard"
                      fullWidth
                      sx={{ mb: 1 }}
                      value={additional}
                      onChange={(event) => setAdditional(event.target.value)}
                    />
                    <IconButton
                      aria-label="Informasjon"
                      onClick={() => (handleInfoClick("additional"))}
                      sx={{ flexShrink: 0 }}
                    >
                      <InfoOutlinedIcon sx={{ color: "#0093ff" }} />
                    </IconButton>
                  </Box>
                  {infoText.additional.visible && (
                    <Typography variant="body2" color="textSecondary">
                      {infoText.additional.text}
                    </Typography>
                  )}

                  <Box sx={{ display: "flex", alignItems: "center", mb: 1, width: "100%" }}>
                    <TextField
                      id="overtime"
                      label="Overtidsgodtgjørelse"
                      aria-label="Tast inn overtidsgodtgjørelse"
                      variant="standard"
                      fullWidth
                      sx={{ mb: 1 }}
                      value={overtime}
                      onChange={(event) => setOvertime(event.target.value)}
                    />
                    <IconButton
                      aria-label="Informasjon"
                      onClick={() => (handleInfoClick("overtime"))}
                      sx={{ flexShrink: 0 }}
                    >
                      <InfoOutlinedIcon sx={{ color: "#0093ff" }} />
                    </IconButton>
                  </Box>
                  {infoText.overtime.visible && (
                    <Typography variant="body2" color="textSecondary">
                      {infoText.overtime.text}
                    </Typography>
                  )}
                </Box>

                {/* Dropdpown menus */}
                <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "flex-start", width: "100%", flexGrow: 1 }}>
                  <Box sx={{ display: "flex", alignItems: "center", mb: 1, width: "100%" }}>
                    <FormControl variant="standard" sx={{ width: "100%" }}>
                      <InputLabel id="group-dropdown">Grupper</InputLabel>
                      <Select
                        id="group-dropdown"
                        label="Grupper"
                        aria-label="Velg gruppe"
                        value={selectedGl4}
                        onChange={(event) => setSelectedGl4(event.target.value)}
                        variant="standard"
                        fullWidth
                        sx={{ mr: 1 }}
                        MenuProps={{
                          // When these are not present, the page jumps to the top when component is used as ifram, not tested with each individually, all might not be necesarry
                          autoFocus: false, // prevents the menu from automatically receiving focus when it opens
                          disableAutoFocusItem: true, // disables the automatic focus on the selected item when the menu closes
                          disableEnforceFocus: true, // stops the menu from trying to enforce focus on itself or its items
                          disableAutoFocus: true // similar to autoFocus, but specifically targets the automatic focus behavior when the menu is opened
                        }}
                      >
                        {availableFilters.length > 0 ? availableFilters[0].group.data.map((item, index) => (
                          <MenuItem key={index} value={item.id}>{item.display_name}</MenuItem>
                        )) : (
                          <MenuItem value="" disabled>Ingen tilgjengelige valg</MenuItem>
                        )}
                      </Select>
                    </FormControl>
                    <IconButton
                      aria-label="Informasjon"
                      onClick={() => (handleInfoClick("group"))}
                      sx={{ flexShrink: 0 }}
                    >
                      <InfoOutlinedIcon sx={{ color: "#0093ff" }} />
                    </IconButton>
                  </Box>
                  {infoText.group.visible && (
                    <Typography variant="body2" color="textSecondary" sx={{ display: "flex", width: "100%", justifyContent: "center" }}>
                      {infoText.group.text}
                    </Typography>
                  )}

                  <Box sx={{ display: "flex", alignItems: "center", mb: 1, width: "100%" }}>
                    <FormControl variant="standard" sx={{ width: "100%" }}>
                      <InputLabel id="educationlevel-dropdown">Utdanningsnivå</InputLabel>
                      <Select
                        id="educationlevel-dropdown"
                        label="Utdanningsnivå"
                        aria-label="Velg utdanningsnivå"
                        disabled={selectedGl4 === ""}
                        value={selectedGl5}
                        onChange={(event) => setSelectedGl5(event.target.value)}
                        variant="standard"
                        fullWidth
                        sx={{ mr: 1 }}
                        MenuProps={{
                          autoFocus: false,
                          disableAutoFocusItem: true,
                          disableEnforceFocus: true,
                          disableAutoFocus: true
                        }}
                      >
                        {gl5Options.length > 0 ? gl5Options.map((item, index) => (
                          <MenuItem key={index} value={item.id}>{item.display_name}</MenuItem>
                        )) : (
                          <MenuItem value="" disabled>Ingen tilgjengelige valg</MenuItem>
                        )}
                      </Select>
                    </FormControl>
                    <IconButton
                      aria-label="Informasjon"
                      onClick={() => (handleInfoClick('educationLevel'))}
                      sx={{ flexShrink: 0 }}
                    >
                      <InfoOutlinedIcon sx={{ color: "#0093ff" }} />
                    </IconButton>
                  </Box>
                  {infoText.educationLevel.visible && (
                    <Typography variant="body2" color="textSecondary" sx={{ display: "flex", width: "100%", justifyContent: "center" }}>
                      {infoText.educationLevel.text}
                    </Typography>
                  )}

                  <Box sx={{ display: "flex", alignItems: "center", mb: 1, width: "100%" }}>
                    <FormControl variant="standard" sx={{ width: "100%" }}>
                      <InputLabel id="years-education-dropdown">År siden fullført utdanning</InputLabel>
                      <Select
                        id="years-education-dropdown"
                        label="Utdanningsnivå"
                        aria-label="Velg År siden fullført utdanning"
                        disabled={selectedGl5 === ""}
                        value={selectedGl6}
                        onChange={(event) => setSelectedGl6(event.target.value)}
                        variant="standard"
                        fullWidth
                        sx={{ mr: 1 }}
                        MenuProps={{
                          autoFocus: false,
                          disableAutoFocusItem: true,
                          disableEnforceFocus: true,
                          disableAutoFocus: true
                        }}
                      >
                        {gl6Options.length > 0 ? gl6Options.map((item, index) => (
                          <MenuItem key={index} value={item.id}>{item.display_name}</MenuItem>
                        )) : (
                          <MenuItem value="" disabled>Ingen tilgjengelige valg</MenuItem>
                        )}
                      </Select>
                    </FormControl>
                    <IconButton
                      aria-label="Informasjon"
                      onClick={() => (handleInfoClick("yearsSinceEducation"))}
                      sx={{ flexShrink: 0 }}
                    >
                      <InfoOutlinedIcon sx={{ color: "#0093ff" }} />
                    </IconButton>
                  </Box>
                  {infoText.yearsSinceEducation.visible && (
                    <Typography variant="body2" color="textSecondary" sx={{ display: "flex", width: "100%", justifyContent: "center" }}>
                      {infoText.yearsSinceEducation.text}
                    </Typography>
                  )}
                </Box>

                {/* Checkbox infotext, and buttons */}
                <Box sx={{ marginTop: "100px" }}>
                  {/* <Box sx={{
                    display: "flex",
                    marginTop: 1,
                  }}>
                    <FormGroup>
                      <FormControlLabel
                        control={<Checkbox defaultChecked />}
                        label="Dersom du ikke ønsker at Parat skal lagre dataene må du fjerne denne avkrysningen. Hva det innebærer at Parat lagrer dataene kan du lese om her" // Add link later
                        aria-label="Avkrysningsboks for samtykke til lagring av data"
                        sx={{
                          "& .MuiFormControlLabel-label": {
                            fontSize: "0.7rem", // Default font size
                            "@media (min-width: 600px)": {
                              fontSize: "0.9rem", // Font size on medium screens and up
                            },
                          },
                        }}
                      />
                    </FormGroup>
                  </Box> */}

                  <Box sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: 3,
                    gap: 1
                  }}>
                    <Button
                      variant="outlined"
                      color="primary"
                      sx={{ width: "100%", height: 50, fontSize: { xs: "0.7rem", sm: "1rem" } }}
                      onClick={resetButtonClick}
                      aria-label="Nullstill alle felter"
                    >
                      Nullstille valg
                    </Button>
                    <Button
                      variant="contained"
                      size="large"
                      color="primary"
                      sx={{ width: "100%", height: 50, fontSize: { xs: "0.7rem", sm: "1rem" } }}
                      onClick={calculateButtonClick}
                      aria-label="Kalkuler lønn"
                    >
                      Kalkuler
                    </Button>
                  </Box>
                </Box>
              </>
            ) : null}
          </Box>
        )}
      </Box>
    </Box>
  );
}
