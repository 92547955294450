import { Alert, Box, Button, CircularProgress, IconButton, Paper, TextField, Tooltip, Typography, useTheme } from '@mui/material';
import { ReactNode, useEffect, useState } from 'react';
import { useApi } from '../../../services/HttpService';
import { InfoArea } from '../../../components/ui/InfoArea';
import { Contact } from '../../../model/User';
import { GridColDef } from '@mui/x-data-grid';
import BaseDataGridTable from '../../../components/tables/BaseDataGridTable';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import DeleteIcon from '@mui/icons-material/Delete';
import ClearIcon from '@mui/icons-material/Clear';
import { ContactsRounded } from '@mui/icons-material';
import { dayjsService } from '../../../services/dayjsService';
import { SingleDatePicker } from '../../../components/ui/SingleDatePicker';
import HardDeleteDialog from '../../../components/modals/dialog/HardDeleteDialog';
import { Accessgroup } from '../../../model/System';
import { MultipleSelectChip } from '../../../components/ui/DropdownSelectChip';
import { CenterSpinner } from '../../../components/ui/CenterSpinner';

interface OidcUser {
  id: number;
  name: string;
  password_hash: string;
  pw_expiredate: Date;
  telephone: string;
  emailaddress: string;
  first_name: string;
  last_name: string;
  username: string;
  oidc_id: string;
  email_verified: boolean;
  phone_verified: boolean;
  is_admin: boolean;
}

function emptyOidcUser(): OidcUser {
  return {
    id: 0,
    name: "",
    password_hash: "",
    pw_expiredate: new Date,
    telephone: "",
    emailaddress: "",
    first_name: "",
    last_name: "",
    username: "",
    oidc_id: "",
    email_verified: false,
    phone_verified: false,
    is_admin: false,
  }
}

interface UserContact {
  id: number,
  name: string,
  oidc_user_id: number,
  contact_id: number,
  from_date: Date,
  to_date: Date,
}

interface UserAccessgroup {
  id: number,
  name: string,
  oidc_user_id: number,
  accessgroup_id: number,
  from_date: Date,
  to_date: Date,
}

const oidcUserCols: GridColDef[] = [
  { field: 'id', headerName: 'ID', width: 70 },
  { field: 'name', headerName: 'Name', width: 130 },
  { field: 'telephone', headerName: 'Phone', width: 130 },
  { field: 'emailaddress', headerName: 'Email', width: 130 },
  { field: 'username', headerName: 'Username', width: 130 },
  { field: 'is_admin', headerName: 'Admin', width: 70 },
];

function isNowBetweenDates(from, to) {
  const now = new Date();
  const fromDate = from ? new Date(from) : null;
  const toDate = to ? new Date(to) : null;

  if (!fromDate && !toDate) {
    return true;
  } else if (fromDate && !toDate) {
    return now >= fromDate;
  } else if (!fromDate && toDate) {
    return now <= toDate;
  } else if (fromDate && toDate) {
    return now >= fromDate && now <= toDate;
  }

  return false;
}

export const UserContact = (props: { contact: Contact }) => {
  const theme = useTheme();
  const { api, response, error, isLoading } = useApi();
  const { api: oidcApi, response: oidcResponse, error: oidcError, isLoading: oidcIsLoading } = useApi(); // @todo: can be done once
  const { api: accessgroupApi, response: accessgroupResponse, error: accessgroupError, isLoading: accessgroupIsLoading } = useApi(); // @todo: can be done once
  const { api: userAccessApi, response: userAccessResponse, error: userAccessError, isLoading: userAccessIsLoading } = useApi();
  const [contact, setContact] = useState<Contact>({} as Contact);
  const [oidcUser, setOidcUser] = useState<OidcUser>(emptyOidcUser());
  const [oidcUsers, setOidcUsers] = useState<OidcUser[]>([]);
  const [userContact, setUserContact] = useState<UserContact>({} as UserContact);
  const [userContacts, setUserContacts] = useState<UserContact[]>([]);
  const [deleteUserContactId, setDeleteUserContactId] = useState(0);
  const [deleteOidcUserId, setDeleteOidcUserId] = useState(0);
  const [accessgroups, setAccessgroups] = useState<Accessgroup[]>([]); // available accessgroup
  const [userAccessgroup, setUserAccessgroup] = useState<UserAccessgroup[]>([]); // connected accessgroup to oidcUser
  const [userAccessgroupCopy, setUserAccessgroupCopy] = useState<UserAccessgroup[]>([]);

  useEffect(() => {
    if (props?.contact?.id) {
      setContact(props.contact);
      getUserContact();
      oidcApi("oidcUsers", "GET");
      accessgroupApi("accessgroup", "get")
    }
  }, [props.contact]);

  useEffect(() => {
    if (response?.statusCode === 200) {
      setUserContacts(response.user_contacts);
      setUserContact({} as UserContact);
    }
  }, [response]);

  useEffect(() => {
    if (accessgroupResponse?.statusCode === 200) {
      setAccessgroups(accessgroupResponse.accessgroups);
      console.log("res", accessgroupResponse.accessgroups)
    }
  }, [accessgroupResponse]);

  const getUserContact = () => {
    api("userContact/" + props.contact.id, "GET");
  }

  const postUserContact = () => {
    api("/userContact", "POST", userContact);
  }

  const deleteUserContact = () => {
    api("/userContact/" + deleteUserContactId, "DELETE", userContact).then(res => {
      if (res?.statusCode === 200) {
        setDeleteUserContactId(0);
      }
    });
  }

  useEffect(() => {
    if (oidcResponse?.statusCode === 200) {
      if (oidcResponse.oidc_user) {
        setOidcUser(oidcResponse.oidc_user);
        setUserAccessgroup(oidcResponse.user_accessgroups);
        setUserAccessgroupCopy(oidcResponse.user_accessgroups);
      } else {
        resetOidcUser();
      }
      setOidcUsers(oidcResponse.oidc_users);
    }
  }, [oidcResponse]);

  const selectOidcUser = () => {
    const data = {
      name: contact.name + " - " + oidcUser.name,
      oidc_user_id: oidcUser.id,
      contact_id: contact.id,
    }
    api("/userContact", "POST", data);
  }

  const postOidcUser = () => {
    const data = {
      oidc_user: oidcUser,
      user_accessgroups: userAccessgroup,
    }
    oidcApi("/oidcUsers", "POST", data)
  }

  const resetOidcUser = () => {
    setOidcUser(emptyOidcUser());
    setUserAccessgroup([]);
    setUserAccessgroupCopy([]);
  }

  const deleteOidcUser = () => {
    oidcApi("/oidcUsers/" + deleteOidcUserId, "DELETE").then(res => {
      if (res?.statusCode === 200) {
        setDeleteOidcUserId(0);
        getUserContact();
      }
    });
  }

  const getUserAccessgroups = (user_id: number) => {
    userAccessApi("/userAccessgroups/" + user_id, "GET").then(res => {
      if (res?.statusCode === 200 && res.user_accessgroups) {
        setUserAccessgroup(res.user_accessgroups);
        setUserAccessgroupCopy(res.user_accessgroups);
      }
    })
  }

  const handleRowSelectionModelChange = (ids) => {
    const newSelectedSet = new Set(ids);
    const selected = oidcUsers?.filter(row => newSelectedSet.has(row.id));
    if (selected?.length > 0) {
      setOidcUser(selected[0]);
      getUserAccessgroups(selected[0].id);
    }
  }

  const handleUserContactEditClick = (user: UserContact) => {
    setUserContact(user);
    handleRowSelectionModelChange([user.oidc_user_id]);
  }

  const handleUserAccessgroupChange = (ids: number[]) => {
    let selectedAccessgroups = accessgroups.filter(v => ids.includes(v.id));
    let newUserAccessgroups: UserAccessgroup[] = [];

    // loop over selectedAccessgroups, check if exists in userAccessgroupCopy
    selectedAccessgroups.forEach(v => {
      let item = userAccessgroupCopy?.find(u => u.accessgroup_id === v.id);
      if (!item) {
        item = {
          id: 0,
          name: oidcUser.name + " - " + v.name,
          oidc_user_id: oidcUser.id,
          accessgroup_id: v.id,
          from_date: null,
          to_date: null,
        }
      }
      newUserAccessgroups.push(item);
    });

    setUserAccessgroup(newUserAccessgroups);
  }

  return (
    <>
      <HardDeleteDialog
        open={deleteUserContactId > 0}
        loading={isLoading}
        errorMessage={error}
        onClose={() => setDeleteUserContactId(0)}
        onConfirm={deleteUserContact}
      />
      <HardDeleteDialog
        open={deleteOidcUserId > 0}
        bodyText={"Are you sure you want to delete? All attached contacts will be affected."}
        loading={oidcIsLoading}
        errorMessage={oidcError}
        onClose={() => setDeleteOidcUserId(0)}
        onConfirm={deleteOidcUser}
      />
      <Box sx={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap', gap: 2 }}>
        <Paper elevation={6} sx={{ width: '100%', maxWidth: { xs: 512, md: 768 }, p: 2 }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', gap: 2 }}>
            <Typography variant='h5' sx={{ mt: 2 }}>{contact.name} settings</Typography>

            {error && <Alert sx={{ width: '100%' }} severity="error">{error}</Alert>}

            <InfoArea title='Attached oidc users'>
              {userContacts?.length > 0 ? (
                <>
                  {userContacts?.map((user, index) => {
                    let isActive = isNowBetweenDates(user.from_date, user.to_date);

                    return (
                      <Paper key={index} elevation={2} sx={{ width: '100%', p: 1 }}>
                        <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', gap: 2 }}>
                          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', flexWrap: 'wrap', gap: 2 }}>
                            <Typography variant='body1' sx={{ width: 100 }}>ID: {user.id}</Typography>
                            <Typography variant='body1' sx={{ width: 70, backgroundColor: isActive ? theme.palette.success.main : theme.palette.error.main, textAlign: 'center' }}>{isActive ? 'Active' : 'Inactive'}</Typography>
                            {user.id !== userContact.id ? (
                              <>
                                <Typography variant='body1' sx={{ width: 200 }}>{`${dayjsService.toDateDisplayString(user.from_date)} - ${dayjsService.toDateDisplayString(user.to_date)}`}</Typography>
                                <Typography variant='body1' sx={{ width: 150 }}>{user.name}</Typography>
                              </>
                            ) : (
                              <>
                                <Typography variant='body1' sx={{ width: 200 }}>{`${dayjsService.toDateDisplayString(user.from_date)} - ${dayjsService.toDateDisplayString(user.to_date)}`}</Typography>
                                <TextField
                                  label="Name"
                                  value={userContact.name}
                                  onChange={(e) => setUserContact({ ...userContact, name: e.target.value })}
                                  variant="standard"
                                  sx={{ width: { xs: '100%', md: 200 } }}
                                />
                                <SingleDatePicker
                                  label="From Date"
                                  date={userContact.from_date}
                                  onChange={(date) => setUserContact({ ...userContact, from_date: date })}
                                />
                                <SingleDatePicker
                                  label="To Date"
                                  date={userContact.to_date}
                                  onChange={(date) => setUserContact({ ...userContact, to_date: date })}
                                />
                              </>
                            )}
                          </Box>
                          {user.id !== userContact.id && (
                            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'end', gap: 2 }}>
                              <Tooltip title="Delete">
                                <IconButton sx={{ p: 0 }} size="small" onClick={() => setDeleteUserContactId(user.id)}>
                                  <DeleteIcon sx={{ color: theme.palette.error.main }} />
                                </IconButton>
                              </Tooltip>
                              <Tooltip title="Edit">
                                <IconButton sx={{ p: 0 }} edge="end" size="small" onClick={() => handleUserContactEditClick(user)}>
                                  <ManageAccountsIcon sx={{ color: theme.palette.mode === "dark" ? "white" : "black" }} />
                                </IconButton>
                              </Tooltip>
                            </Box>
                          )}
                        </Box>
                        {user.id === userContact.id && (
                          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'end', flexWrap: 'wrap', gap: 2, p: 1 }}>
                            <Button variant='contained' color='darkgrey' onClick={() => setUserContact({} as UserContact)}>Cancel</Button>
                            <Button
                              variant='contained'
                              color='info'
                              onClick={postUserContact}
                              disabled={isLoading}
                              endIcon={isLoading && <CircularProgress size={22} />}
                            >
                              Save
                            </Button>
                          </Box>
                        )}
                      </Paper>
                    )
                  })}
                </>
              ) : (
                <Alert sx={{ width: '100%' }} severity="info">No users attached</Alert>
              )}
            </InfoArea>
          </Box>
        </Paper >

        <Paper elevation={6} sx={{ width: '100%', maxWidth: { xs: 512, md: 768 }, p: 2 }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', gap: 2 }}>
            <Typography variant='h5' sx={{ mt: 2 }}>Oidc user settings</Typography>

            {oidcError && <Alert sx={{ width: '100%' }} severity="error">{oidcError}</Alert>}

            <InfoArea title={oidcUser.id > 0 ? 'Edit user' : 'Create user'}>
              {oidcUser.id > 0 && <Alert sx={{ width: '100%' }} severity="warning">Changing a user will affect all associated contacts</Alert>}
              <TextField
                label="Name"
                value={oidcUser.name}
                onChange={(e) => setOidcUser({ ...oidcUser, name: e.target.value })}
                variant="standard"
              />

              {/** attached accessgroup to a oidc user */}
              <Typography variant='h6'>Access Groups</Typography>
              {userAccessError && <Alert sx={{ width: '100%' }} severity="error">{userAccessError}</Alert>}
              <MultipleSelectChip
                label={"Select Access Groups"}
                inputs={accessgroups}
                selectField={"name"}
                selected={userAccessgroup.map((ug) => ug.accessgroup_id)}
                onChange={handleUserAccessgroupChange}
              />
              <CenterSpinner loading={userAccessIsLoading} />
              {!userAccessIsLoading && userAccessgroup?.length > 0 ? (
                <>
                  {userAccessgroup?.map((userAccess, index) => {
                    let isActive = isNowBetweenDates(userAccess.from_date, userAccess.to_date);

                    return (
                      <Paper key={index} elevation={2} sx={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', gap: 1, p: 1 }}>
                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', flexWrap: 'wrap', gap: 1 }}>
                          <Typography variant='body1' sx={{ width: 30 }}>{userAccess.id}</Typography>
                          <Tooltip title={isActive ? 'Active' : 'Inactive'}>
                            <Typography variant='body1' sx={{ width: 20, backgroundColor: isActive ? theme.palette.success.main : theme.palette.error.main, textAlign: 'center' }}>{isActive ? 'A' : 'In'}</Typography>
                          </Tooltip>
                          <Box sx={{ width: 180 }}>
                            <SingleDatePicker
                              label="From Date"
                              date={userAccess.from_date}
                              onChange={(date) => setUserAccessgroup((prev) => prev.map((ug) => ug.id === userAccess.id ? { ...ug, from_date: date } : ug))}
                            />
                          </Box>
                          <Box sx={{ width: 180 }}>
                            <SingleDatePicker
                              label="To Date"
                              date={userAccess.to_date}
                              onChange={(date) => setUserAccessgroup((prev) => prev.map((ug) => ug.id === userAccess.id ? { ...ug, to_date: date } : ug))}
                            />
                          </Box>
                          <TextField
                            label="Name"
                            value={userAccess.name}
                            onChange={(e) => setUserAccessgroup((prev) => prev.map((ug) => ug.id === userAccess.id ? { ...ug, name: e.target.value } : ug))}
                            variant="standard"
                            size='small'
                            sx={{ width: { xs: '100%', md: 200 } }}
                          />
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'end'}}>
                          <Tooltip title="Remove">
                            <IconButton sx={{ p: 0 }} size="small" onClick={() => setUserAccessgroup((prev) => prev.filter((ug) => ug.accessgroup_id !== userAccess.accessgroup_id))}>
                              <ClearIcon sx={{ color: theme.palette.error.main }} />
                            </IconButton>
                          </Tooltip>
                        </Box>
                      </Paper>
                    )
                  })}
                </>
              ) : (
                <>
                  {(!userAccessIsLoading && oidcUser.id > 0) && <Alert sx={{ width: '100%' }} severity="info">User has no access</Alert>}
                </>
              )}

              <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap', gap: 2, p: 1 }}>
                <Box sx={{ display: 'flex', gap: 2, p: 1 }}>
                  <Button variant='contained' color='success' disabled={oidcUser.id === 0 || isLoading} onClick={selectOidcUser}>Select</Button>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'end', flexWrap: 'wrap', gap: 2, p: 1 }}>
                  {oidcUser.id > 0 && (
                    <Button variant='contained' color='error' onClick={() => setDeleteOidcUserId(oidcUser.id)}>Delete</Button>
                  )}
                  {oidcUser.id > 0 && (
                    <Button variant='contained' color='darkgrey' onClick={resetOidcUser}>Cancel</Button>
                  )}
                  <Button
                    variant='contained'
                    color='info'
                    onClick={postOidcUser}
                    disabled={oidcIsLoading}
                    endIcon={oidcIsLoading && <CircularProgress size={22} />}
                  >
                    {oidcUser.id > 0 ? "Update" : "Create"}
                  </Button>
                </Box>
              </Box>
            </InfoArea>

            <InfoArea title={'Available users'}>
              <Box sx={{ height: 400, width: "100%" }}>
                <BaseDataGridTable
                  rows={oidcUsers}
                  columns={oidcUserCols}
                  loading={oidcIsLoading}
                  disableMultipleRowSelection
                  onRowSelectionModelChange={(ids) => { handleRowSelectionModelChange(ids) }}
                />
              </Box>
            </InfoArea>

          </Box>
        </Paper>
      </Box >
    </>
  )
}