import { ReactElement, ReactNode, useEffect, useRef, useState } from 'react';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import * as page from '../pages';
import * as admin from '../pages/admin';
import TopBarLayout from '../components/layouts/TopBarLayout';
import FullLayout from '../components/layouts/FullLayout';
import { authStore } from '../App';
import { createMenuitemHirarchy, Menuitem } from '../model/System';
import { AuthService } from '../services/AuthService';
import { getValidToken } from '../services/HttpService';
import { MenuitemStatus } from '../model/General';

// DO NOT CHANGE PATH OF EXISTING PAGES
// if you do, remember to rename every occurance in sys_menuitem.url to mach the new name.
//
// all pages that require a valid access token from oidc
export const authPathPages = new Map<string, JSX.Element>([
  ["/home", <page.Home />],
  ["/organisasjon", <page.Organisasjon />],
  ["/medlem", <page.Medlem />],
  ["/bedrift", <page.Bedrift />],
  ["/tillitsverv", <page.Tillitsverv />],
  ["/stipend", <page.Stipend />],
  ["/tariff", <page.Tariff />],
  ["/økonomi", <page.Økonomi />],
  ["/kommunikasjon", <page.Kommunikasjon />],

  ["/profile", <page.Profile />],
  ["/Settings", <page.Settings />],

  ["/admin/table", <admin.TableOverview />],
  ["/admin/manageMembers", <admin.ManageMembers />],
  ["/admin/settings", <admin.AdminSettings />],
  ["/admin/databasestate", <admin.DatabaseState />],
  
  ["/hour/hourRegistration", <page.HourReg />],
  ["/hour/myHours", <page.MyHours />],
  ["/hour/hourAccepted", <page.HourAccepted />],
  ["/hour/hourHistory", <page.HoursHistory />],
]);

export const AuthenticatedRoutes = () => {
  const { isLoggedin, mainRoutes, profileRoutes } = authStore();
  const [isLoading, setIsLoading] = useState(false);
  const { authenticate, logout } = AuthService();
  const navigate = useNavigate();

  useEffect(() => {
    authenticate(true).then(success => {
      if (!success) {
        logout();
        navigate("/", { replace: true });
      }
    });
  }, []);

  useEffect(() => {
    const getMenuItems = async () => {
      setIsLoading(true);
      console.log("getting menuitem from AuthenticatedRoutes");
      let token = await getValidToken(false);

      // asume authenticated if token exisits
      if (!token) {
        return;
      }

      const response = await fetch("/api/menuitems", {
        method: "GET",
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });

      if (response?.ok) {
        let res = await response.json();
        const mainRoutes = res.routes.filter(route => route.status === MenuitemStatus.Main) ?? [];
        const profileRoutes = res.routes.filter(route => route.status === MenuitemStatus.Profile) ?? [];
        authStore.setState({ 
          mainRoutes: createMenuitemHirarchy(mainRoutes),
          profileRoutes: createMenuitemHirarchy(profileRoutes),
        });
      } else {
        authStore.setState({ mainRoutes: [], profileRoutes: [] });
      }
      setIsLoading(false);
    }

    getMenuItems();
  }, [isLoggedin]);

  function constructAuthRoutes(menuitems: Menuitem[]): ReactElement[] {
    let availableRoutes: ReactElement[] = [];

    if (menuitems) {
      menuitems.sort((a, b) => a.priority - b.priority);

      menuitems.forEach(menuitem => {
        const elem = authPathPages.get(menuitem.url);
        if (elem) {
          availableRoutes.push(<Route key={menuitem.id} path={menuitem.url} element={elem} />)
        }

        if (menuitem.children.length > 0) {
          availableRoutes.push(...constructAuthRoutes(menuitem.children));
        }
      })
    }

    return availableRoutes;
  }

  if (!isLoggedin || isLoading) {
    return null;
  }

  return (
    <>
      <TopBarLayout>
        <Routes>
          <Route path="/home" element={<page.Home />} />

          {constructAuthRoutes(mainRoutes)}
          {constructAuthRoutes(profileRoutes)}

          {/** @todo: on prod, replace these with <Route path="/*" element={<Navigate to='/' replace />} /> */}
          <Route path="/:path" element={<page.NotFound />} />
          <Route path="/*" element={<page.NotFound />} />
        </Routes>
      </TopBarLayout>
    </>
  );
};

const AdminRoutes = () => {
  const { userRoles } = authStore();
  return (
    <Routes>
      {userRoles.includes("admin") ? (
        <>
          <Route path="/table" element={<admin.TableOverview />} />
          <Route path="/manageMembers" element={<admin.ManageMembers />} />
          <Route path="/settings" element={<admin.AdminSettings />} />
          <Route path="/databasestate" element={<admin.DatabaseState />} />
          <Route path="/*" element={<Navigate to='table' replace />} />
        </>
      ) : (
        <Route path="/*" element={<page.MissingPermission />} />
      )}
    </Routes>
  );
};