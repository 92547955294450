import { AppBar, Box, Paper, Tab, Tabs, tabsClasses, useTheme } from '@mui/material';
import React, { useState } from 'react';
import { TabPanel, TabProps } from '../../../components/ui/TabPanel';
import { MenuAccessSettings } from './MenuAccessSettings';
import { MenuSettings } from './MenuSettings';
import { PageSettings } from './PageSettings';
import { RoleSettings } from './RoleSettings';
import { useLocation } from 'react-router-dom';

export const AdminSettings = () => {
  const theme = useTheme();
  
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const tabFromUrl = parseInt(queryParams.get('menutab')) || 0;
  const [tabIndex, setTabIndex] = useState(tabFromUrl);

  const handleTabChange = (newValue) => {
    setTabIndex(newValue);
    const newUrl = `${location.pathname}?menutab=${newValue}`;
    window.history.replaceState(null, '', newUrl);
  };

  return (
    <Paper elevation={6} sx={{ mt: { xs: 3, md: 6 }, mb: "50vh", width: '100%', maxWidth: { md: 768 }, p: 2 }}>
      <Box sx={{ width: '100%', mt: 2 }}>
        <AppBar position="static" sx={
          {
            backgroundColor: theme.palette.mode === 'dark' ? "inherit" : theme.palette.grey[300],
            color: theme.palette.mode === 'dark' ? "white" : "black",
          }
        }>
          <Tabs
            value={tabIndex}
            onChange={(_, index) => handleTabChange(index)}
            textColor="inherit"
            indicatorColor="primary"
            variant="scrollable"
            aria-label="admin settings tabs"
            scrollButtons
            sx={{
              [`& .${tabsClasses.scrollButtons}`]: {
                '&.Mui-disabled': { opacity: 0.3 },
              },
            }}
          >
            {["Page", "Role", "Menu", "Menu Access", "Item 4", "Item 5", "Item 6", "Item 7"].map((name, index) => (
              <Tab key={index} label={name} sx={{ fontWeight: "bold" }} {...TabProps(index)} />
            ))}
          </Tabs>
        </AppBar>
      </Box>
      <TabPanel value={tabIndex} index={0}>
        <PageSettings />
      </TabPanel>
      <TabPanel value={tabIndex} index={1}>
        <RoleSettings />
      </TabPanel>
      <TabPanel value={tabIndex} index={2}>
        <MenuSettings />
      </TabPanel>
      <TabPanel value={tabIndex} index={3}>
        <MenuAccessSettings />
      </TabPanel>
      <TabPanel value={tabIndex} index={4}>
        Item 4
      </TabPanel>
      <TabPanel value={tabIndex} index={5}>
        Item 5
      </TabPanel>
      <TabPanel value={tabIndex} index={6}>
        Item 6
      </TabPanel>
      <TabPanel value={tabIndex} index={7}>
        Item 7
      </TabPanel>
    </Paper>
  )
}