import { Alert, AlertColor, Autocomplete, Box, Button, CircularProgress, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip, Typography, useTheme } from '@mui/material';
import { ReactNode, useEffect, useRef, useState } from 'react';
import { useApi } from '../../services/HttpService';
import { InfoArea } from '../../components/ui/InfoArea';
// import { createMenuitemHirarchy, countHierarchyChildren, Menuitem, newMenuitem } from '../../model/System';
import { HourRegistration, newHourRegistration, createHourRegistrionsList, Project, createProjectList} from '../../model/Hour';
import HardDeleteDialog from '../../components/modals/dialog/HardDeleteDialog';
import { CenterSpinner } from '../../components/ui/CenterSpinner';
import DeleteIcon from '@mui/icons-material/Delete';
import React from 'react';
import { SingleDatePicker } from '../../components/ui/SingleDatePicker';
import { ClickableIcon } from '../../components/ui/Icon';
import EditIcon from '@mui/icons-material/Edit';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';

export const HoursHistory = () => {
  const theme = useTheme();
  // const { api, error, isLoading } = useApi();
  const { api: hourRegistrationsApi, response: hourRegistrationsResponse, isLoading: hourRegistrationsIsLoading, error: hourRegistrationsError } = useApi();
  const [hourRegistration, setHourRegistration] = useState<HourRegistration>(newHourRegistration);
  const [hourRegistrationsHistory, setHourRegistrationsHistory] = useState<HourRegistration[]>([]);
  const [projectList, setProjectList] = useState<Project[]>([]);
  const editRef = useRef(null); // scroll to edit on edit click
  const executeScroll = () => editRef.current.scrollIntoView();

  useEffect(() => {
    getHours();
  }, []);

  const getHours = () => {
    hourRegistrationsApi("/hour/getHourRegistrations").then(res => {
      if (res && res.statusCode === 200) {
        setHourRegistrationsHistory(res.data.historyhours)
        setProjectList(res.data.projects)
      }
    });
  }

  const projectMap = React.useMemo(() => {
    const map = {};
    projectList.forEach(project => {
      map[project.id] = project; // You can map the whole project if needed
    });
    return map;
  }, [projectList]);

  /**
   * Unified error validation function.
   * @param field - The name of the field to validate.
   * @returns An error message if validation fails, or an empty string if valid.
   */

  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', gap: 2 }}>
        {/* <Typography variant='h5' sx={{ mt: 2 }}>Menu settings</Typography> */}

        {hourRegistrationsError && <Alert sx={{ width: "100%" }} severity="error">{hourRegistrationsError}</Alert>}
        <InfoArea title='Accepted hours'>
          <CenterSpinner loading={hourRegistrationsIsLoading} />
          {!hourRegistrationsIsLoading && (
            <>
              {hourRegistrationsHistory?.length > 0 ? (
                <TableContainer component={Paper} sx={{ mt: 2 }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell><Typography variant="body1">Date</Typography></TableCell>
                            <TableCell><Typography variant="body1">Project</Typography></TableCell>
                            <TableCell><Typography variant="body1">Hours</Typography></TableCell>
                            <TableCell><Typography variant="body1"></Typography></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {hourRegistrationsHistory?.map((item, index) => (
                            <TableRow key={index} hover onClick={() => setHourRegistration(item)}>
                                <TableCell>{new Date(item.hour_date).toLocaleDateString('da-NO')}</TableCell>
                                <Tooltip title={item.name || ''} arrow>
                                    <TableCell>{projectMap[item.hour_project_id].name || "Unknown Project"}</TableCell>
                                </Tooltip>
                                <TableCell>{item.hour.toString()}</TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
              </TableContainer>
              ) : (
                <>
                  <Alert severity='warning'>No hour registrations</Alert>
                </>
              )}
              
            </>
          )}
        </InfoArea>

      </Box>
    </>
  )
}