  export interface HourRegistration {
    id: number;
    hour_project_id: number;
    type: number;
    hour_date: Date;
    hour: number;
    name: string;
  }

  export interface Project {
    id: number;
    type: number;
    name: string;
  }
  
  export const newHourRegistration = (): HourRegistration => ({
    id: 0,
    hour_project_id: 0,
    type: 0,
    hour_date: new Date(),
    hour: 0.0,
    name: "",
  });

  export const createHourRegistrionsList = (
    data: any[]
  ): HourRegistration[] => {
    return data.map((item) => ({
      id: item.id,
      hour_project_id: item.hour_project_id,
      type: 1,
      hour_date: new Date(item.hour_date), 
      hour: item.hour,
      name: item.name,
    }));
  };
  

  export const createProjectList = (
    data: any[]
  ): Project[] => {
    return data.map((item) => ({
      id: item.id,
      type: item.type,
      name: item.name,
    }));
  };