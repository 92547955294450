import { ReactNode } from "react";
import { IconButton, Tooltip, useTheme } from "@mui/material";

interface ClickableIconProps {
  title: string;
  children?: ReactNode;
  disabled?: boolean;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

export function ClickableIcon(props: ClickableIconProps) {
  const { title, children, disabled, onClick } = props;
  const theme = useTheme();

  return (
    <Tooltip title={title}>
      <IconButton
        disabled={disabled}
        onClick={(event) => onClick(event)}
        edge="end"
        color='inherit'
        size="small"
        sx={{ p: "2px", "&:hover": { backgroundColor: theme.palette.mode === "dark" ? "#232323" : "" } }}
      >
        {children}
      </IconButton>
    </Tooltip>
  )
}