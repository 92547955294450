import * as React from 'react';
import { Theme, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Chip from '@mui/material/Chip';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

interface multiSelectProps {
  label: string;
  inputs: any[];
  selectField: string;
  selected: number[];
  disabled?: boolean;
  disabledItems?: number[];
  onChange: (ids: number[]) => void
}

export const MultipleSelectChip: React.FC<multiSelectProps> = ({ label, inputs, selectField, selected, disabled, disabledItems, onChange }) => {
  const theme = useTheme();

  const handleChange = (event: SelectChangeEvent<number[]>) => {
    const { value } = event.target;
    const selectedIds = typeof value === 'string' ? value.split(',').map(Number) : value;
    onChange(selectedIds);
  };

  const handleItemClick = (id: number) => {
    const newSelected = selected.includes(id)
      ? selected.filter((itemId) => itemId !== id) // Remove if already selected
      : [...selected, id]; // Add if not selected

    onChange(newSelected);
  };

  const handleClear = (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
    onChange([]);
  };

  const getStyles = (id: number, selected: number[], theme: Theme) => {
    const isSelected = selected.includes(id);
    
    return {
      fontWeight: isSelected
        ? theme.typography.fontWeightMedium
        : theme.typography.fontWeightRegular,
      backgroundColor: isSelected
        ? theme.palette.primary.main
        : 'inherit',
    };
  }

  return (
    <FormControl variant='outlined' sx={{ width: '100%' }}>
      <InputLabel id="multiple-chip-label">{label}</InputLabel>
      <Select
        labelId="multiple-chip-label"
        id="multiple-chip"
        multiple
        value={selected}
        // onChange={handleChange}
        disabled={disabled}
        input={<OutlinedInput id="select-multiple-chip" label={label} />}
        renderValue={(selectedIds) => (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
            {selectedIds.map((id) => {
              const selectedItem = inputs.find((input) => input.id === id);
              return selectedItem ? (
                <Chip key={id} label={selectedItem[selectField]} />
              ) : null;
            })}
          </Box>
        )}
        // MenuProps={MenuProps}
      >
        <MenuItem
          value={null}
          onClick={handleClear}
          sx={{ fontWeight: theme.typography.fontWeightMedium }}
        >
          Clear
        </MenuItem>
        {inputs?.map((input) => (
          <MenuItem
            key={input.id}
            value={input.id}
            onClick={() => handleItemClick(input.id)}
            disabled={disabledItems?.includes(input.id)}
            style={getStyles(input.id, selected, theme)}
          >
            {input.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

interface singleSelectChipProps {
  label: string;
  inputs: any;
  selectField: string;
  selected: number;
  onChange: (id: number) => void
}

export const SingleSelectChip: React.FC<singleSelectChipProps> = ({ label, inputs, selectField, selected, onChange }) => {
  const theme = useTheme();

  const handleChange = (event: SelectChangeEvent<number>) => {
    const { value } = event.target;
    const selectedId = typeof value === 'string' ? Number(value) : value;
    onChange(selectedId);
  };

  const displayedChip = (selectedId) => {
    let chipContent = null;
    if (selectedId > 0) {
      const selectedItem = inputs.find((input) => input.id === selectedId);
      if (selectedItem) {
        chipContent = <Chip key={selectedId} label={selectedItem[selectField]} />;
      }
    }

    return <>{chipContent}</>;
  }

  const handleNoneSelect = () => {
    onChange(0);
  };

  return (
    <FormControl variant='outlined' sx={{ width: '100%' }}>
      <InputLabel id="single-chip-label">{label}</InputLabel>
      <Select
        labelId="single-chip-label"
        id="single-chip"
        value={selected > 0 ? selected : ''}
        onChange={handleChange}
        input={<OutlinedInput id="single-select-chip" label={label} />}
        renderValue={(selectedId) => (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
            {displayedChip(selectedId)}
          </Box>
        )}
      >
        <MenuItem
          value=""
          onClick={handleNoneSelect}
          sx={{ fontWeight: theme.typography.fontWeightMedium }}
        >
          None
        </MenuItem>
        {inputs?.map((input) => (
          <MenuItem
            key={input.id}
            value={input.id}
            sx={{ fontWeight: theme.typography.fontWeightMedium }}
          >
            {input[selectField]}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}