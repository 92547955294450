import { Alert, AlertColor, Autocomplete, Box, Button, CircularProgress, IconButton, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip, Typography, useTheme } from '@mui/material';
import { ReactNode, useEffect, useRef, useState } from 'react';
import { useApi } from '../../services/HttpService';
import { InfoArea } from '../../components/ui/InfoArea';
// import { createMenuitemHirarchy, countHierarchyChildren, Menuitem, newMenuitem } from '../../model/System';
import { HourRegistration, newHourRegistration, createHourRegistrionsList, Project, createProjectList} from '../../model/Hour';
import HardDeleteDialog from '../../components/modals/dialog/HardDeleteDialog';
import { CenterSpinner } from '../../components/ui/CenterSpinner';
import DeleteIcon from '@mui/icons-material/Delete';
import React from 'react';
import { SingleDatePicker } from '../../components/ui/SingleDatePicker';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import { ClickableIcon } from '../../components/ui/Icon';
import EditIcon from '@mui/icons-material/Edit';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import { Margin } from '@mui/icons-material';

export const HourReg = () => {
  const theme = useTheme();
  // const { api, error, isLoading } = useApi();
  const { api: hourRegistrationsApi, response: hourRegistrationsResponse, isLoading: hourRegistrationsIsLoading, error: hourRegistrationsError } = useApi();
  const { api: saveHourRegApi, response: saveHourRegResponse, isLoading: saveHourRegIsLoading, error: saveHourRegError } = useApi();
  const { api: deleteHourRegApi, response: deleteHourRegResponse, isLoading: deleteHourRegIsLoading, error: deleteHourRegError } = useApi();
  const [hourRegistration, setHourRegistration] = useState<HourRegistration>(newHourRegistration);
  const [selectedProject, setSelectedProject] = useState<Project>();
  const [selected, setSelected] = useState<HourRegistration>({} as HourRegistration);
  const [registeredHour, setRegisteredHour] = useState<HourRegistration>({} as HourRegistration); // item to be deleted
  const [openDelete, setOpenDelete] = useState(false);
  const [hourRegistrations, setHourRegistrations] = useState<HourRegistration[]>([]);
  const [hourRegistrationsAccepted, setHourRegistrationsApproved] = useState<HourRegistration[]>([]);
  const [hourRegistrationsHistory, setHourRegistrationsHistory] = useState<HourRegistration[]>([]);
  const [projectList, setProjectList] = useState<Project[]>([]);
  const [touched, setTouched] = useState<{ [key: string]: boolean }>({}); // track edited fields
  const [submitted, setSubmitted] = useState(false);
  const [isEditing, setIsEditing] = useState(true);
  const [message, setMessage] = useState('');
  const [messageType, setMessageType] = useState<AlertColor | undefined>('error');

  const editRef = useRef(null); // scroll to edit on edit click
  const executeScroll = () => editRef.current.scrollIntoView();

  useEffect(() => {
    getHours();
  }, []);

  const getHours = () => {
    hourRegistrationsApi("/hour/getHourRegistrations").then(res => {
      if (res && res.statusCode === 200) {
        setHourRegistrations(res.data.myhours)
        setHourRegistrationsHistory(res.data.historyhours)
        setHourRegistrationsApproved(res.data.acceptedhours)
        setProjectList(res.data.projects)
      }
    });
  }

  const projectMap = React.useMemo(() => {
    const map = {};
    projectList.forEach(project => {
      map[project.id] = project; // You can map the whole project if needed
    });
    return map;
  }, [projectList]);

  const postHourRegistration = async () => {
    const hasErrors = Object.keys(hourRegistration).some(
      (key) => validateField(key as keyof HourRegistration) !== ""
    );

    if (hasErrors){
      setSubmitted(true);
      return
    }

    if (!hasErrors) {
      await saveHourRegApi("/hour/postHourRegistration", "POST", { ...hourRegistration}).then(res => {
        if (res && res.statusCode === 200) {
          setHourRegistration(newHourRegistration);
          setRegisteredHour(hourRegistration)
          // getHours();
          setSubmitted(true);
          setIsEditing(false);
        }else{
          if (res.error) {
            if (res.error.code === "1") {
              setDisplayMessage("Error fetching hour registration data");
            } else if (res.error.code === "2") {
              setDisplayMessage("Error parsing date");
            } else if (res.error.code === "3") {
              setDisplayMessage("Error updating registration");
            } else if (res.error.code === "4") {
              setDisplayMessage("Error creating hour registration");
            } else {
              setDisplayMessage("Error creating hour registration");
            }
          }
        }
      });
    } else {
      setSubmitted(false);
    }
  }

  const setDisplayMessage = (message: string, type: AlertColor = 'error') => {
    setMessage(message);
    setMessageType(type);
  }

  const handleNewRegistration = () =>{
    setSelectedProject(null);
    setHourRegistration(newHourRegistration);
    toggleEditing();
  }

  // const handleEditRegistration = (event, item) =>{
  //   event.stopPropagation();
  //   setSelectedProject(projectMap[item.hour_project_id]);
  //   setHourRegistration(item);
  //   toggleEditing();
  // }


  const toggleEditing = () => {
    setIsEditing(!isEditing);
  };

  // const deleteHourRegistration = async () => {
  //   await deleteHourRegApi("/hour/deleteHourRegistration", "POST", { ...selected }).then(res => {
  //     if (res && res.statusCode === 200) {
  //       setSelected({} as HourRegistration);
  //       setOpenDelete(false);
  //       setDisplayMessage("test123")
  //       getHours();
  //     }
  //   });
  // }

  const handleDeleteClick = (event, registration) => {
    event.stopPropagation();
    setSelected(registration);
    setOpenDelete(true);
  }

  const handleChange = (field: keyof HourRegistration, value: string) => {
    setHourRegistration((prev) => ({ ...prev, [field]: value }));

    if (!touched[field]) {
      setTouched((prev) => ({ ...prev, [field]: true }));
    }
  }

  const handleNumberChange = (field: keyof HourRegistration, value: number) => {
    setHourRegistration((prev) => ({ ...prev, [field]: value }));

    if (!touched[field]) {
      setTouched((prev) => ({ ...prev, [field]: true }));
    }
  }

  const handleProjectChange = (event: React.SyntheticEvent, newValue: Project | null) => {
    if (newValue) {
      setSelectedProject(newValue);
      setHourRegistration((prev) => ({
        ...prev,
        hour_project_id: newValue.id, // Set the selected project's ID
      }));
    } else {
      setSelectedProject(null);
      setHourRegistration((prev) => ({
        ...prev,
        hour_project_id: 0, // Reset to default if no project is selected
      }));
    }
  }

  const handleHourChange = (event) => {
    const value = parseFloat(event.target.value);
    if (!isNaN(value) && value % 0.5 === 0) {
        setHourRegistration((prev) => ({
            ...prev,
            hour: value,
        }));
    }
  }

  const handleIncrement = (amount) => {
    setHourRegistration((prev) => ({
        ...prev,
        hour: Math.max(0, parseFloat((prev.hour + amount).toFixed(1))),
    }));
  };

  const handleSetHour = (amount) => {
    setHourRegistration((prev) => ({
        ...prev,
        hour: amount,
    }));
  };

  /**
   * Unified error validation function.
   * @param field - The name of the field to validate.
   * @returns An error message if validation fails, or an empty string if valid.
   */
  const validateField = (field: keyof HourRegistration): string => {
    if (field === "hour_project_id") {
      if (hourRegistration.hour_project_id === 0) return "Required";
    }
    if (field === "hour"){
      if (hourRegistration.hour === 0) return "Required"
    }
    if (field === "name") {
      if (hourRegistration.name.trim() === "") return "Description cannot be empty";
      if (hourRegistration.name.trim().length < 5) return "Description must be at least 5 characters long";
    }
    return ""; // No error
  };

  return (
    <>
      {/* <HardDeleteDialog
        open={openDelete}
        bodyText={`Are you sure you want to delete hour registration for "${selected?.hour_project_id}" ? Click "DELETE" to confirm.`}
        loading={deleteHourRegIsLoading}
        errorMessage={deleteHourRegError}
        onClose={() => setOpenDelete(false)}
        onConfirm={() => deleteHourRegistration()}
      /> */}

      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', gap: 2 }}>
        {/* <Typography variant='h5' sx={{ mt: 2 }}>Menu settings</Typography> */}

        {hourRegistrationsError && <Alert sx={{ width: "100%" }} severity="error">{hourRegistrationsError}</Alert>}

        {!isEditing && ( 
          <Box
            sx={{
              padding: 2,
              border: '1px solid grey',
              borderRadius: '8px',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              maxWidth: 700,
              paddingX: 10,
              paddingY: 10,
              margin: 'auto',
              boxShadow: 4,
            }}
          >

            <Typography variant="h6">
                Hour registered for:
            </Typography>
            <Typography variant="h6" sx={{ marginBottom: 2 }}>
                {projectMap[registeredHour.hour_project_id].name}
            </Typography>

            <Stack direction="row" spacing={3} alignItems="center" sx={{ marginBottom: 2 }}>
                <Typography variant="h6" sx={{ display: 'flex', alignItems: 'center' }}>
                    <CalendarTodayIcon sx={{ marginRight: 1 }} />
                    {new Date(registeredHour.hour_date).toLocaleDateString('da-NO')}
                </Typography>
                <Typography variant="h6" sx={{ display: 'flex', alignItems: 'center' }}>
                    <AccessTimeIcon sx={{ marginRight: 1 }} />
                    {registeredHour.hour}
                </Typography>
            </Stack>

            <Typography variant="h6" sx={{ marginBottom: 5 }}>
                {registeredHour.name}
            </Typography>

            <Button variant="contained" onClick={handleNewRegistration}>
              New Registration
            </Button>
          </Box>
          
        )}

        {/* CREATE / EDIT FILEDS */}
        {isEditing && (
        <Box ref={editRef} sx={{ width: "100%" }}>
          {message && <div><br /><Alert severity={messageType}>{message}</Alert></div>}
          <InfoArea title={hourRegistration.id > 0 ? "Edit Hour Registration" : "Register Hour"}>
            {/* FORM FIELDS */}
            <Autocomplete
              aria-required
              disablePortal
              options={projectList} // List of projects
              getOptionLabel={(option) => option.name} // Display project name
              renderInput={(params) => <TextField {...params} label="Select Project" />}
              onChange={handleProjectChange} // Handle project selection
              value={selectedProject}
              // sx={{ width: 300 }}
              // renderInput={(params) => <TextField {...params} label="Project" />}
            />
            <Box display="flex" alignItems="center" gap={1}  flexWrap="wrap">
            <TextField
              label="Hours"
              type="number"
              value={hourRegistration.hour}
              onChange={handleHourChange}
              error={validateField("hour") !== "" && (submitted || touched.hours)}
              helperText={(submitted || touched.hours) ? validateField("hour") : ""}
              onBlur={() => setTouched((prev) => ({ ...prev, hours: true }))}
              inputProps={{
                  step: "0.5",
                  min: 0,
              }}
              // sx={{ width: 120, textAlign: 'center' }}
              sx={{
                width: { xs: '100%', sm: 120 }, // Full width on small screens, 120px on larger screens
                textAlign: 'center',
            }}
            />
            <Button 
                onClick={() => handleSetHour(8)} 
                sx={{ fontSize: { xs: '0.75rem', sm: '1rem' }, padding: { xs: '4px 8px', sm: '6px 12px' } }}
            >
                8 hours
            </Button>
            <Button 
                variant="contained" 
                onClick={() => handleIncrement(0.5)} 
                sx={{ fontSize: { xs: '0.75rem', sm: '1rem' }, padding: { xs: '4px 8px', sm: '6px 12px' } }}
            >
                + 0.5
            </Button>
            <Button 
                variant="contained" 
                onClick={() => handleIncrement(1)} 
                sx={{ fontSize: { xs: '0.75rem', sm: '1rem' }, padding: { xs: '4px 8px', sm: '6px 12px' } }}
            >
                + 1
            </Button>
            <Button 
                variant="contained" 
                onClick={() => handleIncrement(-0.5)} 
                sx={{ fontSize: { xs: '0.75rem', sm: '1rem' }, padding: { xs: '4px 8px', sm: '6px 12px' } }}
            >
                - 0.5
            </Button>
            <Button 
                variant="contained" 
                onClick={() => handleIncrement(-1)} 
                sx={{ fontSize: { xs: '0.75rem', sm: '1rem' }, padding: { xs: '4px 8px', sm: '6px 12px' } }}
            >
                - 1
            </Button>
            </Box>
            <TextField
              label="Description"
              required
              multiline
              rows={2}
              value={hourRegistration.name}
              onChange={(e) => handleChange("name", e.target.value)}
              variant="filled"
              error={validateField("name") !== "" && (submitted || touched.description)}
              helperText={(submitted || touched.description) ? validateField("name") : ""}
              onBlur={() => setTouched((prev) => ({ ...prev, description: true }))}
            />
            <SingleDatePicker
              label="Dato"
              date={hourRegistration.hour_date}
              onChange={(date) => setHourRegistration({ ...hourRegistration, hour_date: date })}
            />

            <Box sx={{ width: '100%', display: 'flex', justifyContent: 'end', flexWrap: 'wrap', gap: 2, p: 2 }}>
              <Button
                variant='contained'
                color='darkgrey'
                onClick={() => {
                  setHourRegistration(newHourRegistration);
                  setTouched({});
                  setSubmitted(false);
                  toggleEditing();
                }}
              >
                Cancel
              </Button>
              <Button
                variant='contained'
                color='info'
                onClick={postHourRegistration}
                disabled={saveHourRegIsLoading}
                endIcon={saveHourRegIsLoading && <CircularProgress size={22} />}
              >
                {hourRegistration.id > 0 ? "Update" : "Create"}
              </Button>
            </Box>
          </InfoArea>
        </Box>
        )}

        {/* <InfoArea title='My hours'>
          <CenterSpinner loading={hourRegistrationsIsLoading} />
          {!hourRegistrationsIsLoading && (
            <>
              {hourRegistrations?.length > 0 ? (
                <TableContainer component={Paper} sx={{ mt: 2 }}>
                  <Table>
                      <TableHead>
                          <TableRow>
                              <TableCell><Typography variant="body1">Date</Typography></TableCell>
                              <TableCell><Typography variant="body1">Project</Typography></TableCell>
                              <TableCell><Typography variant="body1">Hours</Typography></TableCell>
                              <TableCell><Typography variant="body1"></Typography></TableCell>
                          </TableRow>
                      </TableHead>
                      <TableBody>
                          {hourRegistrations?.map((item, index) => (
                              <TableRow key={index} hover onClick={() => setHourRegistration(item)}>
                                  <TableCell>{new Date(item.hour_date).toLocaleDateString('da-NO')}</TableCell>
                                  <Tooltip title={item.name || ''} arrow>
                                      <TableCell>{projectMap[item.hour_project_id].name || "Unknown Project"}</TableCell>
                                  </Tooltip>
                                  <TableCell>{item.hour.toString()}</TableCell>
                                  <TableCell>
                                      <IconButton title="Edit" onClick={(event) => handleEditRegistration(event, item)}>
                                          <EditIcon color='inherit' />
                                      </IconButton>
                                      <IconButton title="Delete" onClick={(event) => handleDeleteClick(event, item)}>
                                          <DeleteIcon color='error' />
                                      </IconButton>
                                  </TableCell>
                              </TableRow>
                          ))}
                      </TableBody>
                  </Table>
                </TableContainer>
              ) : (
                <>
                  <Alert severity='warning'>No hour registrations</Alert>
                </>
              )}
              
            </>
          )}
        </InfoArea> */}

        {/* <InfoArea title='Accepted hours'>
          <CenterSpinner loading={hourRegistrationsIsLoading} />
          {!hourRegistrationsIsLoading && (
            <>
              {hourRegistrationsAccepted?.length > 0 ? (
                <TableContainer component={Paper} sx={{ mt: 2 }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell><Typography variant="body1">Date</Typography></TableCell>
                            <TableCell><Typography variant="body1">Project</Typography></TableCell>
                            <TableCell><Typography variant="body1">Hours</Typography></TableCell>
                            <TableCell><Typography variant="body1"></Typography></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {hourRegistrationsAccepted?.map((item, index) => (
                            <TableRow key={index} hover onClick={() => setHourRegistration(item)}>
                                <TableCell>{new Date(item.hour_date).toLocaleDateString('da-NO')}</TableCell>
                                <Tooltip title={item.name || ''} arrow>
                                    <TableCell>{projectMap[item.hour_project_id].name || "Unknown Project"}</TableCell>
                                </Tooltip>
                                <TableCell>{item.hour.toString()}</TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
              </TableContainer>
              ) : (
                <>
                  <Alert severity='warning'>No hour registrations</Alert>
                </>
              )}
              
            </>
          )}
        </InfoArea> */}

        {/* <InfoArea title='Hour history'>
          <CenterSpinner loading={hourRegistrationsIsLoading} />
          {!hourRegistrationsIsLoading && (
            <>
              {hourRegistrationsHistory?.length > 0 ? (
                <TableContainer component={Paper} sx={{ mt: 2 }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell><Typography variant="body1">Date</Typography></TableCell>
                            <TableCell><Typography variant="body1">Project</Typography></TableCell>
                            <TableCell><Typography variant="body1">Hours</Typography></TableCell>
                            <TableCell><Typography variant="body1"></Typography></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {hourRegistrationsHistory?.map((item, index) => (
                            <TableRow key={index} hover onClick={() => setHourRegistration(item)}>
                                <TableCell>{new Date(item.hour_date).toLocaleDateString('da-NO')}</TableCell>
                                <Tooltip title={item.name || ''} arrow>
                                    <TableCell>{projectMap[item.hour_project_id].name || "Unknown Project"}</TableCell>
                                </Tooltip>
                                <TableCell>{item.hour.toString()}</TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
              </TableContainer>
              ) : (
                <>
                  <Alert severity='warning'>No hour registrations</Alert>
                </>
              )}
              
            </>
          )}
        </InfoArea> */}
      </Box>
    </>
  )
}