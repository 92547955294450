import React, { useState, useRef, useEffect } from 'react';
import { AlertProps, Form } from 'react-bootstrap';
import { Alert, AlertColor, CircularProgress } from '@mui/material';
import { Box, useTheme } from '@mui/material';

function timeout(delay: number) {
  return new Promise(res => setTimeout(res, delay));
}

export const OidcLogin = () => {
  const [isloading, setIsloading] = useState(true);
  const [message, setMessage] = useState('');
  const [messageType, setMessageType] = useState<AlertColor | undefined>('error');
  const hasFetchedRef = React.useRef(false);

  React.useEffect(() => {
    const getUserData = async () => {
      if (hasFetchedRef.current) {
        return;
      }
      hasFetchedRef.current = true;

      await fetch("/api/login", {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(
          { path: "/home" } // can be changed to a default landing page after completing login  for individual tenants
        ),
      }).then(res => {
        console.log("oidcLogin res", res);
        if (res.ok && res.redirected) {
          window.location.href = res.url;
        } else {
          if (res.status >= 400 && res.status < 500) {
            setMessage("The login is not correctly configured");
          } else if (res.status >= 500 && res.status < 600) {
            setMessage("The request could not be handled");
          } else if (res.ok && !res.redirected) {
            setMessage("The client refused to redirect");
          } else {
            setMessage("Something went wrong");
          }
        }
      }).catch(err => {
        console.log("oidcLogin err", err);
        setMessage("Oidc login unavailable");
      }).finally(() => {
        setIsloading(false);
      });
    }

    getUserData();
  }, []);

  return (
    <Box sx={{ width: "100%", height: "100vh", display: "flex", alignItems: "center", justifyContent: "center" }}>
      {isloading ? (
        <CircularProgress color="info" />
      ) : message ? (
        <Alert severity='error' sx={{ width: 500 }}>{message}</Alert>
      ) : <></>}
    </Box>
  )
}