import React, { useEffect } from "react";
import { AppRouter } from "./routes/AppRouter";
import BaseLayout from "./components/layouts/BaseLayout";
import { create } from "zustand";
import "./App.css";
import "./pages/public/poster/components/i18n";
import { AuthService } from "./services/AuthService";
import { getValidToken, useApi } from "./services/HttpService";
import { themeStore } from "./components/themes/BaseTheme";
import { createMenuitemHirarchy, Menuitem } from "./model/System";
import { authPathPages } from "./routes/AuthenticatedRoutes";

export const authStore = create<{
  isLoggedin: boolean;
  initialLoad: boolean;
  userName: string;
  userRoles: string[];
  mainRoutes: Menuitem[];
  profileRoutes: Menuitem[];
}>((setState, getState) => ({
  isLoggedin: false,
  initialLoad: false,
  userName: "",
  userRoles: [],
  mainRoutes: [],
  profileRoutes: [],
}));

export const tenantStore = create<{
  name: string;
  displayName: string;
}>((setState, getState) => ({
  name: "",
  displayName: "",
}));

function App() {

  useEffect(() => {
    fetch("/api/getInfo", {
      method: "GET",
      headers: {
        'Content-Type': 'application/json',
      },
    }).then(response => {
      if (response.ok) {
        response.json().then(res => {
          let pal = res.palette;
          themeStore.getState().updateColors(pal.primary, pal.secondary, pal.success);
          tenantStore.setState({ name: res.name, displayName: res.displayName });
        })
      }
    }).finally(() => {
      authStore.setState({ initialLoad: true });
    })
  }, []);

  return (
    <BaseLayout>
      <AppRouter />
    </BaseLayout>
  );
}

export default App;
